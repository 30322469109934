<template>
  <v-col cols="12" sm="12" style="margin-bottom:150px;">
    <v-row>
      <v-col cols="12" sm="5" md="3" class="px-5 ml-sm-auto">
        <v-img @click="fetchCrewInfos()" src="../../assets/img/black_fire_crew-600.png"/>
      
        <v-card color="black" class="px-3">
          <v-card-text>
            <small>Retrouvez à tout moment l'ensemble des messages envoyés au 
              <b><i>Black Faya Crew</i></b>, et discutez-en avec les autres membres...
            </small>
          </v-card-text>

          <template v-for="(crewMsg, i) in crewMessages">
            <v-list :key="i" color="black" class="pa-0">
              <v-list-item two-line class="list-item-inbox"  @click="selectMsg(crewMsg)" >
                <v-list-item-content>
                  <v-list-item-title>{{ i+1 }}# {{ crewMsg.subject }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <div class="pr-1 d-inline-block" v-if="crewMsg.status == 'SENT'">Envoyé à {{ crewMsg.to.length }} membres • </div> 
                    <time-ago :datetime="crewMsg.updated" :refresh="60" long 
                              :locale="$vuetify.lang.current"></time-ago>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-card>

        <v-btn text block class="mt-5 d-none d-sm-inline" @click="showPopupExitCrew=true">Quitter le groupe</v-btn>
      </v-col>

      <v-col cols="12" sm="7" md="7" class="mr-sm-auto px-0" id="cardmsg">
        
        <v-card color="black" class="pa-3 pa-md-5 card-crewmsg" v-if="currentMsg != null">
          <v-card-title class="pl-0 font-papyrus">
            <b class="">Objet : </b> {{ currentMsg.subject }} <br> 
            <small>
              <time-ago :datetime="currentMsg.updated" :refresh="60" long 
                        :locale="$vuetify.lang.current"></time-ago>
            </small>
          </v-card-title>
          <v-divider class=""></v-divider>
          <v-row class="my-0">
            <v-toolbar elevation="0" color="transparent">
              <v-btn v-if="currentMsg.status == 'READY'" 
                    color="primary" outlined class="mr-3"
                    @click="editMessage()">
                <v-icon>mdi-pencil</v-icon> Modifier
              </v-btn>
              <v-btn v-if="currentMsg.status == 'READY'" 
                    color="primary" class="black--text"
                    @click="sendMessage()">
                <v-icon>mdi-send</v-icon> Envoyer le message
              </v-btn>
              <v-btn v-else color="blue" rounded outlined @click="openDialogComments()">
                <v-icon small class="mr-1">mdi-comment</v-icon> {{ currentMsg.nbComments }} commentaire{{ currentMsg.nbComments > 1 ? 's' : '' }}
              </v-btn>
            </v-toolbar>
          </v-row>
          <v-divider></v-divider>

          <v-card-text class="px-0 font-papyrus text-crewmsg" v-html="nl2br(currentMsg.message)"/>

        </v-card>
        
        <v-card color="black" class="pa-3 pa-md-5  card-crewmsg text-center" min-height="400" v-else-if="crewMessages.length > 0">
          <h2 class="font-papyrus" style="margin-top:150px;">
            <v-icon x-large>mdi-mail</v-icon><br>
            Sélectionnez un message
          </h2>
        </v-card>
        
        <v-card color="black" class="pa-4 text-center" min-height="400" v-else-if="!loading">
          <h2 class="font-papyrus" style="margin-top:150px;">
            <v-icon x-large>mdi-mail</v-icon><br>
            Aucun message
          </h2>
          Il n'y a aucun message à consulter pour l'instant.
        </v-card>
      </v-col>
      <v-col cols="12" class="text-right">
        <v-btn text block class="mt-5 d-sm-none" @click="showPopupExitCrew=true">Quitter le groupe</v-btn>
      </v-col>
    </v-row>

    <!-- <DialogComments/> -->


  <v-dialog v-model="showPopupExitCrew" 
            max-width="750" :fullscreen="$vuetify.breakpoint.width < 600">
    <v-card color="black">
      <v-toolbar color="transparent" class="red--text" elevation="0">
        <v-icon color="red" class="mr-2">mdi-logout</v-icon> 
        Quitter le Black Faya Crew
        <v-spacer></v-spacer>
        <v-btn icon @click="showPopupExitCrew = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-divider/>
      
      <v-card-title>Voulez-vous vraiment quitter le Black Faya Crew ?</v-card-title>
      <v-card-text class="pb-0" id="">
        Vous ne recevrez plus aucun message réservé aux membres.
      </v-card-text>
      
      <br>
      <v-divider/>

      <v-toolbar color="transparent" elevation="0" height="80">
        <v-spacer></v-spacer>
        <v-btn text @click="showPopupExitCrew=false" class="mr-2">
          Annuler
        </v-btn>
        <v-btn rounded color="red" @click="exitFayaCrew()" class="mr-2">
          <v-icon>mdi-logout</v-icon> Quitter le groupe
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
  </v-col>
</template>

<style>
  
  .list-item-inbox{
    border-top:1px solid rgb(34, 34, 34)
  }
  .v-card.card-crewmsg{
    /* border:4px dotted #b6b6b6 !important; */
  }

  @media (max-width: 960px) {

    .v-card.card-crewmsg{
      /* border:2px dotted #b6b6b6 !important; */
      margin:10px;
    }

  }
</style>

<script>

import axios from 'axios'
import {TimeAgo} from "vue2-timeago"

//import DialogComments from '@/components/fayacrew/DialogComments.vue'
import router from '../../router/router'

export default {
  name: 'faya-crew',
  components: { TimeAgo, },
  data: () => ({
    crewMembers: [],
    countCrewMembers: 0,
    showPopupFormMsg: false,
    currentMsg: null,
    crewMessages: [],
    loading: true,
    showPopupExitCrew: false,
  }),
  async mounted(){
    //if(!this.$store.state.auth.user.inBFC) router.push("dashboard")
    this.fetchCrewInfos()
  },
  methods: {
    async fetchCrewInfos(){
      let res = await axios.get("data/get-crew-infos")
      this.loading = false
      this.crewMembers = res.data.crewMembers
      this.countCrewMembers = res.data.countCrewMembers
      this.crewMessages = res.data.crewMessages
    },
    async exitFayaCrew(){
      await axios.get("data/exit-crew")
      this.$store.dispatch('auth/refreshUserData')
      this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'green', 
                                  title: "Vous ne faites plus partie du Black Faya Crew",
                                  text: '',
                                  icon: 'thumb-up' })
      router.push("/dashboard")

    },
    openDialogComments(){
      console.log("openDialogComments")
      this.$root.$emit("openDialogComments", { refId: this.currentMsg._id, refType: 'crewMessage' })
    },
    selectMsg(crewMsg){
      this.currentMsg = crewMsg
      let scroller = document.getElementById("cardmsg")
      let height = scroller.offsetTop
      console.log("height", height)
      setTimeout(()=>{
        window.scrollTo(0, height)
      }, 100)
    },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      
      return newStr
    },
  },
  computed: {    
  },
  watch: {
     
  }, 
}
</script>
